import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_employ_limit_log = _resolveComponent("employ-limit-log")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_auto_limit_log = _resolveComponent("auto-limit-log")!
  const _component_supplement_log = _resolveComponent("supplement-log")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer limitLog" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        animated: false,
        class: "secction-tabs",
        size: "large"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "员工"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_employ_limit_log)
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "2",
            tab: "车辆"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_auto_limit_log)
            ]),
            _: 1
          }),
          (_ctx.isScheduleModel)
            ? (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: "3",
                tab: "油补模式"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_supplement_log)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}