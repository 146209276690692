
import { defineComponent, ref, reactive } from 'vue';
import { message } from 'ant-design-vue';

import { noEmptyProp, not, dispatchDownload, formatWithIntl } from '@/utils/illuminate';
import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import { formatDate } from '@/core/filters';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import type { TableColumn } from '@/components/Table';

const statusList = ['未发放', '全部发放', '部分发放'];
const statusClassList = ['text-red', 'text-green', ''];

export default defineComponent({
  name: 'SupplementLog',
  setup(props, ctx) {
    const store = useStore();
    const filterParams = reactive<{
      nameOrPhone: string;
      timeRange: Moment[];
      status: string;
      enterpriseId: string | number;
    }>({
      nameOrPhone: '',
      timeRange: [],
      status: '',
      enterpriseId: store.state.User.enterprise.enterpriseId,
    });

    const tableRef = ref();

    const columns: TableColumn[] = [
      { dataIndex: 'supplementDate', title: '日期', width: '120px', customRender: ({ text }) => formatDate(text, 'YYYY-MM-DD') },
      { dataIndex: 'userName', title: '员工信息', width: '190px', customRender: ({ text, record }) => (`${record.userName} - ${record.phone}`) },
      { dataIndex: 'licensePlate', title: '车牌号码', width: '110px' },
      { dataIndex: 'totalQuota', title: '总补贴额度上限(元)', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'quota', title: '油费补贴额度上限(元)', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'expresswayQuota', title: '高速费补贴额度上限(元)', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'additionalAmount', title: '应发高速费(元)', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'status', title: '油补发放', width: '110px', align: 'center', customRender: ({ text, record }) => (statusList[+text]) },
      { dataIndex: 'budgetAmount', title: '应发油费（元）', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'drivenDistance', title: '里程数(公里)', width: '120px', customRender: ({ text, record }) => `${text} km` },
      { dataIndex: 'oilConsume', title: '油耗（L/100km）', width: '180px', align: 'center', customRender: ({ text, record }) => `${text} L/100km` },
      { dataIndex: 'amount', title: '已发放油费金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'unrecordedAmount', title: '未发放油费金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'expresswayAmount', title: '已发放高速费金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'expresswayUnrecordedAmount', title: '未发放高速费金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'totalAmount', title: '已发放总补贴金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'totalUnrecordedAmount', title: '未发放总补贴金额(元)', align: 'center', width: '150px', customRender: ({ text, record }) => (text ? formatWithIntl(toLocalUnit(text)) : '--') },
      { dataIndex: 'oilPrice', title: '当时油价(元)', align: 'center', width: '120px' },
      { dataIndex: 'oilCost', title: '油补升数', width: '130px', align: 'center' },
      { dataIndex: 'approveNo', title: '订单号', width: '180px', align: 'center' },
      { dataIndex: 'enterpriseName', title: '公司', width: '180px' },
    ];

    const loadTableData = async (params: any) => {
      const p: any = {
        ...not(noEmptyProp(filterParams), 'timeRange'),
      };

      if (filterParams.timeRange.length > 0) {
        p.startDate = filterParams.timeRange[0].format('YYYY-MM-DD');
        p.endDate = filterParams.timeRange[1].format('YYYY-MM-DD');
      }

      try {
        const res = await request.post(createApiUrl('/newlinkSass/quota/oil-supplement/search/page-list'), { ...p, ...params }, { noEnterpriseId: true });
        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const onExport = async () => {
      const params : Record<string, any> = { ...noEmptyProp(filterParams) };

      if (filterParams.timeRange.length > 0) {
        params.startDate = filterParams.timeRange[0].format('YYYY-MM-DD');
        params.endDate = filterParams.timeRange[1].format('YYYY-MM-DD');
        delete params.timeRange;
      }
      let res: any;
      try {
        res = await request.post(createApiUrl('/newlinkSass/excel/export/quota/oil-supplement/search'), { ...params }, { responseType: 'blob' });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`油补额度调整日志${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
    };

    return {
      filterParams,
      tableRef,
      columns,

      loadTableData,
      onRefreshTable,
      onExport,
    };
  },
});
