
import { defineComponent, ref, reactive, watchEffect, getCurrentInstance, onMounted } from 'vue';
import { formatCny, dispatchDownload, settleTree } from '@/utils/illuminate';
import { message } from 'ant-design-vue';

import moment, { Moment } from 'moment';
import { useStore } from 'vuex';
import { formatDate } from '@/core/filters';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'EmployLimitLog',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();

    const filterParams = reactive<{
      nameOrPhone: string;
      timeRange: Moment[];
      enterpriseId: number;
      departmentId: number | undefined,
    }>({
      nameOrPhone: '',
      timeRange: [],
      enterpriseId: store.state.User.enterprise?.enterpriseId,
      departmentId: undefined,
    });

    const tableRef = ref();

    const columns: TableColumn[] = [
      { dataIndex: 'createTime', title: '调整时间', width: '180px', customRender: ({ text }) => formatDate(text) },
      { dataIndex: 'userName', title: '员工姓名', width: '120px', ellipsis: true },
      { dataIndex: 'phone', title: '手机号码', width: '150px' },
      { dataIndex: 'operationType', title: '操作类型', width: '140px' },
      { dataIndex: 'operationContent', title: '操作内容', width: '140px' },
      { dataIndex: 'beforeBalance', title: '调整前可用额度', width: '140px', customRender: ({ text }) => formatCny(String(text / 100)) },
      { dataIndex: 'afterBalance', title: '调整后可用额度', width: '140px', customRender: ({ text }) => formatCny(String(text / 100)) },
      { dataIndex: 'effectiveTime', title: '生效时间', width: '180px', customRender: ({ text }) => formatDate(text) },
      { dataIndex: 'operationName', title: '操作人', width: '180px', customRender: ({ text, record }) => `${record.operationName || '---'}${record.operationPhone ? `-${record.operationPhone}` : ''}` },
    ];

    const onRefreshTable = async (params: any) => {
      const p: any = {
        enterpriseId: filterParams.enterpriseId,
        departmentId: filterParams.departmentId,
      };

      if (filterParams.nameOrPhone.trim() !== '') {
        p.nameOrPhone = filterParams.nameOrPhone;
      }
      if (filterParams.timeRange.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        p.startDateTime = filterParams.timeRange[0].valueOf();
        // eslint-disable-next-line prefer-destructuring
        p.endDateTime = filterParams.timeRange[1].valueOf();
      }
      try {
        const res = await request.get(createApiUrl('/newlinkSass/quota/ar/log/list'), {
          params: { ...p, ...params },
        });
        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const onRefreshTableOnBtn = () => {
      tableRef.value.refresh(true);
    };

    // 导出参数配置
    const exportParams = reactive<{
      timeRange: Moment[];
      enterpriseId: number;
      departmentId: number | undefined,
    }>({
      timeRange: [],
      enterpriseId: store.state.User.enterprise?.enterpriseId,
      departmentId: undefined,
    });
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const departmentExport = ref<any[]>([]); // 导出数据使用的部门树
    const loadEnterpriseDepartment = async (val:any, source:any) => {
      // 清空部门
      if (source === 'export') {
        exportParams.departmentId = undefined;
      } else {
        filterParams.departmentId = undefined;
      }
      // 公司查询全部 部门为空
      if (val === '') {
        if (source === 'export') {
          departmentExport.value = [];
        } else {
          departmentList.value = [];
        }
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'), {
          params: {
            enterpriseId: val,
          },
        });
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      if (source === 'export') {
        departmentExport.value = settleTree(res.data.departmentList,
          { key: 'id', title: 'departmentName', value: 'id' },
          'departmentList');
      } else {
        departmentList.value = settleTree(res.data.departmentList,
          { key: 'id', title: 'departmentName', value: 'id' },
          'departmentList');
      }
    };

    watchEffect(() => {
      loadEnterpriseDepartment(filterParams.enterpriseId, '');
    });

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const onExport = () => {
      loadEnterpriseDepartment(exportParams.enterpriseId, 'export');
      Object.assign(exportParams, {
        timeRange: [],
        enterpriseId: exportParams.enterpriseId,
        departmentId: exportParams.departmentId,
      });
      confirmVisible.value = true;
    };

    const handChangeEnterprise = (e: any) => {
      loadEnterpriseDepartment(e, 'export');
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (!exportParams.timeRange.length) {
        message.warning('请选择时间范围在导出');
        return;
      }
      if (exportParams.timeRange.length) {
        params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
        params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      }
      params.enterpriseId = exportParams.enterpriseId;
      params.departmentId = exportParams.departmentId || ''; // 部门
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/down/arQuotaLog'), { responseType: 'blob', params: { ...params } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`员工额度调整日志${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_credit_limit_staff_page_view', { page_name: '额度调整日志_员工页_浏览' });
    });

    return {
      /* params */
      filterParams,
      departmentList,
      departmentExport,
      tableRef,
      columns,
      /* Funcs */
      onRefreshTable,
      onRefreshTableOnBtn,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      onExport,

      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,

      handChangeEnterprise,
    };
  },
});
