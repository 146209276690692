
import { defineComponent, ref, reactive } from 'vue';

import AutoLimitLog from './parts/AutoLimitLog.vue';
import EmployLimitLog from './parts/EmployLimitLog.vue';
import SupplementLog from './parts/SupplementLog.vue';

export default defineComponent({
  name: 'LimitLog',
  components: {
    AutoLimitLog,
    EmployLimitLog,
    SupplementLog,
  },
  setup(props, ctx) {
    return {};
  },
});
