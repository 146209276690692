
import { defineComponent, ref, reactive, getCurrentInstance, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { formatCny, dispatchDownload } from '@/utils/illuminate';
import moment, { Moment } from 'moment';

import { useStore } from 'vuex';
import { formatDate } from '@/core/filters';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { createApiUrl } from '@/utils/utils';

export default defineComponent({
  name: 'AutoLimitLog',
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();

    const filterParams = reactive<{
      licensePlate: string;
      timeRange: Moment[];
      enterpriseId: number,
    }>({
      licensePlate: '',
      timeRange: [],
      enterpriseId: store.state.User.enterprise?.enterpriseId,
    });

    const tableRef = ref();
    const columns: TableColumn[] = [
      { dataIndex: 'createTime', title: '调整时间', width: '180px', customRender: ({ text }) => formatDate(text) },
      { dataIndex: 'licensePlate', title: '车牌号码', width: '120px' },
      { dataIndex: 'operationType', title: '操作类型', width: '140px' },
      { dataIndex: 'operationContent', title: '操作内容', width: '120px' },
      { dataIndex: 'beforeBalance', title: '调整前可用额度', width: '200px', customRender: ({ text, record }) => (record.beforeQuotaFlag ? '无限' : formatCny(String(text / 100))) },
      { dataIndex: 'afterBalance', title: '调整后可用额度', width: '140px', customRender: ({ text, record }) => (record.afterQuotaFlag ? '无限' : formatCny(String(text / 100))) },
      { dataIndex: 'effectiveTime', title: '生效时间', width: '200px', customRender: ({ text }) => formatDate(text) },
      { dataIndex: 'operationName', title: '操作人', width: '126px' },
    ];

    const onRefreshTable = async (params: any) => {
      const p: any = {
        enterpriseId: filterParams.enterpriseId,
      };
      if (filterParams.licensePlate.trim() !== '') {
        p.licensePlate = filterParams.licensePlate;
      }
      if (filterParams.timeRange.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        p.startDateTime = filterParams.timeRange[0].valueOf();
        // eslint-disable-next-line prefer-destructuring
        p.endDateTime = filterParams.timeRange[1].valueOf();
      }
      try {
        const res = await request.get(
          createApiUrl('/newlinkSass/quota/vr/log/list'),
          { params: { ...p, ...params } },
        );
        return res.data;
      } catch (e: Error & any) {
        message.error(e.message);
      }
    };

    const onRefreshTableOnBtn = () => {
      tableRef.value.refresh(true);
    };

    // = ---------------------------- = 导出数据 = ---------------------------- =
    const confirmVisible = ref(false);
    const exportParams = reactive<{
      timeRange: Moment[];
    }>({
      timeRange: [],
    });

    const onExport = () => {
      Object.assign(exportParams, {
        timeRange: [],
      });
      confirmVisible.value = true;
    };

    let firstSelectTime: Moment | null;
    const isDateDisable = (currentDate: Moment) => {
      if (moment().valueOf() - currentDate.valueOf() < 0) return true;
    };
    const onOpenChange = (value: boolean) => {
      if (!value) firstSelectTime = null;
    };

    const onCalendarChange = (dateRange: [Moment, Moment]) => {
      // eslint-disable-next-line prefer-destructuring
      firstSelectTime = dateRange[0];
    };

    const exporting = ref(false);
    const onConfirmExport = async () => {
      const params: Record<string, any> = {};
      if (!exportParams.timeRange.length) {
        message.warning('请选择时间范围在导出');
        return;
      }
      if (exportParams.timeRange.length) {
        params.startDate = exportParams.timeRange[0].format('YYYY-MM-DD');
        params.endDate = exportParams.timeRange[1].format('YYYY-MM-DD');
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/excel/down/vrQuotaLog'), { responseType: 'blob', params: { ...params } });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      const blob = new Blob([res.data], {
        type: 'application/octet-stream;charset=UTF-8',
        endings: 'transparent',
      });

      const url = URL.createObjectURL(blob);
      const dataTime = moment().format('YYYYMMDDhh:mm:ss');
      dispatchDownload(`车辆额度调整日志${dataTime}.xlsx`, url);
      // 释放之前创建的URL对象
      window.URL.revokeObjectURL(url);
      confirmVisible.value = false;
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_credit_limit_car_page_view', { page_name: '额度调整日志_车辆页_浏览' });
    });

    return {
      /* params */
      filterParams,
      tableRef,
      columns,
      /* Funcs */
      onRefreshTable,
      onRefreshTableOnBtn,

      isDateDisable,
      onOpenChange,
      onCalendarChange,

      onExport,

      confirmVisible,
      exporting,
      exportParams,
      onConfirmExport,
    };
  },
});
